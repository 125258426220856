.headingDiv {
    max-width: 800px;
}
.CardDiv {
    max-width: 330px;
}

.buttonBorder {
    border: 1px solid #e54fc4;
}

.forCreator {
    padding: 16px 20px;
    background: conic-gradient(
        from 180deg at 50% 50%,
        #df55ea 0deg,
        #e34dcc 71.25deg,
        #f9c17b 180deg,
        #68d0e0 275.62deg,
        #df55ea 360deg
    );
    border-radius: 250px;
    color: rgba(250, 249, 251, 1);
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 15px;
    display: inline-flex;
}
