.mainDiv {
    background: #201627;
}

.headingDiv {
    z-index: 1;
}

.headingFont {
    font-size: 64px;
    font-weight: 700;
}
@media screen and (max-width: 768px) {
    .headingFont {
        font-size: 48px;
    }
}

.picDiv {
    max-width: 620px;
}

/* .animateImageDiv img {
    animation: glide 1s ease-in-out alternate infinite;
} */

@keyframes glide {
    from {
        left: 0px;
        top: 0px;
    }

    to {
        left: 0px;
        top: 20px;
    }
}
.mPicDiv {
    position: absolute;
    z-index: 0;
    right: 0;
}

@media screen and (max-width: 1024px) {
    .hidePicDiv {
        display: none !important;
    }
    .mNextImage {
        margin: 0;
        padding: 0;
        display: flex !important;
    }
}
@media screen and (min-width: 1024px) {
    .nextImage {
        margin: 0;
        padding: 0;
        display: flex !important;
    }
    .hideMPicDiv {
        display: none !important;
    }
}

/* @media screen and (max-width: 1024px) {
    .picDiv {
        display: none !important;
    }
} */

.gradientBack {
    background: linear-gradient(
        90.39deg,
        #f27acd 18.91%,
        #dcd599 62.08%,
        #74dee4 101.79%
    );
}

@media screen and (min-width: 768px) {
    .nextImage {
        margin: 0;
        padding: 0;
        /* display: flex !important; */
    }
}

.userGreetings {
    font-size: 72px;
}

@media screen and (max-width: 768px) {
    .userGreetings {
        font-size: 48px;
    }
}
