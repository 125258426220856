.statusContainer {
    display: flex;
    align-items: center;

    margin: 0px;
    padding: 0px;
}

.icon {
    margin: 0px;
    padding: 2px;
    padding-left: 0px;
    width: 24px;
    height: 24px;
}

.text {
    margin: 0px;
    margin-left: 4px;
    padding: 0px;

    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.5px;
    text-align: left;
}

@media screen and (max-width: 424px) {
    .icon {
        padding: 0px;
        width: 20px;
        height: 20px;
    }

    .text {
        margin-left: 2px;
        font-size: 10px;
        letter-spacing: 0.25px;
    }
}

.live-now {
    color: #6bd6d6;
}

.live-soon {
    color: #6bd6d6;
}

.coming-soon {
    color: #f9be75;
}
