.cardContainer {
    max-width: 400px;
    padding: 24px 20px;
}

@media screen and (max-width: 330px) {
    .cardContainer {
        max-width: 270px;
        padding: 16px;
    }
}

@media screen and (min-width: 331px) and (max-width: 424px) {
    .cardContainer {
        max-width: 375px;
        padding: 16px;
    }
}

.narrowCardContainer {
    padding: 16px;
    max-width: 400px;
}

.innerContainer {
    padding-left: 12px;
    padding-right: 12px;
}

@media screen and (min-width: 1200px) {
    .narrowCardContainer {
        max-width: 256px !important;
    }

    .innerContainer {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.categoryContainer {
    border: 1px solid #ffe074 !important;
    border-radius: 8px !important;
}

.mediaContainer {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 24px;
}

.mediaImage {
    position: relative;
    border-radius: 24px;
}

.creatorInfoContainer {
    box-shadow: 0px 6px 24px 17px rgba(0, 0, 0, 0.08);
    border-radius: 39px;
    position: absolute;
    background: white;
    bottom: -25px;
    z-index: 1000 !important;
}

.border {
    border: 1px solid #f1eef1;
    border-radius: 24px;
}
.gradientBorder {
    border-radius: 24px;
    background: white;
    position: relative;
    padding: 20px;
}

.gradientBorder::before {
    border-radius: 25px;
    content: '';
    background-image: linear-gradient(0deg, #68d0e0, #f9c17b, #df55ea);
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    position: absolute;
    z-index: -1;
}

.title {
    line-height: 22px !important;
    overflow: hidden;
    margin-bottom: 0px;
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.subTitle {
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.5px;
    text-transform: uppercase;

    color: #565159;
    margin-left: 5px;
}

.description {
    display: flex;
    word-wrap: break-word;
    margin: 0px;
    padding: 0px;
    height: 2.4em;
    line-height: 1.2em !important;
    overflow: hidden;
    margin-top: 10px;
}

.editionCopy {
    margin-top: 1px;
    margin-bottom: 0px;
    font-size: 14px;
}
