.inputDiv {
    position: relative;
    width: 333px;
}

@media screen and (max-width: 420px) {
    .inputDiv {
        width: auto;
    }
}

.inputDiv input {
    padding-right: 35px;
    padding-left: 10px;
    height: 56px;
    border-radius: 8px;
    font-size: 20px;
    color: #201627;
    font-weight: 300;
}
.inputPicDiv {
    display: block;
    position: absolute;
    right: 0;
}
