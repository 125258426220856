.input {
    background: #f1eef1;
    border: none;
    border-radius: 8px;
    font-size: 20px !important;
    font-weight: 300 !important;
    color: #201627;
    letter-spacing: 0.5px;
    padding: 15px;
    height: 41px;
    padding: 10px;
}
.subscribe {
    width: 100%;
    height: 41px;

    border: 1px solid #e54fc4;
    box-sizing: border-box;
    border-radius: 8px;

    background: #e54fc4;
    color: #ffffff;
}
.subscribe:disabled {
    background: #D3D3D3;
    border-color: #D3D3D3;
    color: #ffffff;
}
