.mainDiv {
    background: #f1eef1;
    position: relative;
    padding: 76px 0px;
}
.headingDiv {
    max-width: 700px;
}
.paragDiv {
    max-width: 480px;
}
.femaleIcon {
    position: absolute;
    bottom: 0;
}
.mfemaleIcon {
    position: absolute;
    bottom: 100px;
}
.MaleIcon {
    position: absolute;
    bottom: 0%;
    right: 0;
}
.mMaleIcon {
    position: absolute;
    bottom: 0%;
    right: 0;
}
.pinkItem {
    position: absolute;
    bottom: 22%;
    left: 25%;
}
.purpleItem {
    position: absolute;
    top: 2%;
    right: 20%;
}
.oranmgeItem {
    position: absolute;
    top: 45%;
    right: 17%;
}
.greenItem {
    position: absolute;
    bottom: 28%;
    right: 8%;
}
.pinkLeaf {
    position: absolute;
    top: 25%;
    right: 1%;
}

.greenDot {
    position: absolute;
    bottom: 0%;
    right: 23%;
}
@media screen and (max-width: 850px) {
    .mainDiv {
        height: 530px;
    }
}

@media screen and (max-width: 780px) {
    .femaleIcon {
        position: absolute;
        bottom: 10%;
    }
    .MaleIcon {
        position: absolute;
        bottom: 0%;
        right: 0;
    }
}

@media screen and (max-width: 600px) {
    .mainDiv {
        height: 630px;
    }
}

@media screen and (max-width: 460px) {
    .mainDiv {
        height: 790px;
    }
}
@media screen and (max-width: 340px) {
    .mainDiv {
        height: 890px;
    }
}

.mgreenTItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.mpinkTItem {
    position: absolute;
    top: 0%;
    right: 15%;
}

.mgreenLeaf {
    position: absolute;
    top: 2%;
    right: 5%;
}

.mpinkMidItem {
    position: absolute;
    top: 35%;
    right: 0%;
}

.mgreenMiditem {
    position: absolute;
    top: 45%;
    right: 5%;
}

.morangeItem {
    position: absolute;
    top: 32%;
    left: 1%;
}

.morangeMidnDot {
    position: absolute;
    top: 60%;
    right: 10%;
}

.mPurbleBLeaf {
    position: absolute;
    top: 83%;
    left: 20%;
}

.mgreenBLeaf {
    position: absolute;
    top: 85%;
    left: 8%;
}

.mpurpleBLeaf {
    position: absolute;
    top: 95%;
    left: 30%;
}
