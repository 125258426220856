.authenticationButton {
    font-family: 'Ubuntu', sans-serif !important;
    font-weight: 400 !important;
    height: 100%;
    width: 100%;
    min-width: 19.5rem;
    max-height: 3.5rem;
    color: #565159;
    letter-spacing: 1px;
    font-size: 18px;
    padding: 8px 30px 8px 24px;
    border: 1px solid #F1EEF1;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
}
.authenticationButton:hover {
    color: #000000;
    border-color: #D3D3D3;
}
.authenticationButton:disabled {
    pointer-events: none;
    white-space: nowrap;
}
.authenticationButton > .imgLabel__container {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 440px) {
    .authenticationButton {
        padding: 8px 5px 8px 0px;
        font-size: 12px;
        min-width: 12rem;
    }
}