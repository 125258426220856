.modalContainer {
    max-width: 430px;
    padding: 8px;
}

.confirmText {
    display: flex;
    flex-direction: column;

    margin: 20px 0px 32px 0px;
}

.yesBtn {
    margin: 0px;
    padding: 18px 24px;

    min-height: 56px;

    background: #a12ceb;

    border: none;
    border-radius: 35px;

    color: #fefefe;
}

.noBtn {
    margin: 0px;
    margin-top: 20px;
    padding: 0px;

    border: none;

    background: transparent;
    color: #fefefe;
}
