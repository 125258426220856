.dot {
    width: 4px;
    height: 4px;
    background: white;
    border-radius: 50%;
    margin: 2px;
    animation: dotScale 1s infinite ease-in-out;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes dotScale {
    0%,
    40%,
    100% {
        transform: scale(1);
    }

    15% {
        transform: scale(1.7);
    }
}
