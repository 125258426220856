.creatorContainer {
    width: 250px;
    margin: 8px;
    margin-bottom: 32px;
}

.animateWrapper {
    background-color: #fafafa;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 767px) {
    .animateWrapper {
        padding-top: 10px;
        border-radius: 12px;
    }
}

.animateWrapper:hover {
    transform: scale(1.1);
}

.imageContainer {
    display: flex;
    margin: 0;
    padding: 0;
    width: 100px;
    height: 100px;
    border-radius: 73px;
    overflow: hidden;
}
.imageContainer img {
    object-fit: cover;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    padding-left: 16px;

    width: 150px;
    height: 100px;
    justify-content: center;
}
@media screen and (max-width: 767px) {
    .creatorContainer {
        width: 150px;
        margin: 16px;
    }

    .imageContainer {
        margin: 0;
        padding: 0;

        width: 100px;
        height: 100px;

        border-radius: 73px;

        overflow: hidden;
    }

    .infoContainer {
        padding-left: 0;
    }
}
@media screen and (max-width: 424px) {
    .creatorContainer {
        width: 135px;
        margin: 8px;
    }

    .imageContainer {
        margin: 0;
        padding: 0;

        width: 100px;
        height: 100px;

        border-radius: 73px;

        overflow: hidden;
    }

    .infoContainer {
        padding: 0;
        margin: 0;

        padding-left: 0;
        width: 130px;
        height: 100px;
        overflow: 'hidden';
    }
}
@media screen and (max-width: 374px) {
    .creatorContainer {
        width: 120px;
        margin: 8px;
    }

    .imageContainer {
        margin: 0;
        padding: 0;

        width: 88px;
        height: 88px;

        border-radius: 73px;

        overflow: hidden;
    }

    .infoContainer {
        padding-left: 0;
        width: 130px;
        height: 100px;
        overflow: 'hidden';
    }
}

.name {
    margin: 0;
    padding: 0;

    font-size: 18px;
    font-weight: 400;

    color: #201627;

    word-wrap: break-word;
    height: 24px;
    overflow: hidden;
}

.desc {
    margin: 0px;
    margin-top: 4px;
    padding: 0;

    font-size: 12px;
    font-weight: 300;

    color: #201627;

    height: 18px;
    overflow: hidden;
}

.status {
    margin: 0px;
    padding: 0px;

    height: 24px;
}
